// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconNotes, IconBoxModel2, IconShape2 } from '@tabler/icons';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';

import config from 'config';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconNotes,
    IconBoxModel2,
    IconShape2
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const solutionDirectory = {
    id: 'solution-directory',
    title: <FormattedMessage id="solution-directory" />,
    type: 'group',
    children: [
        {
            id: 'models',
            title: <FormattedMessage id="models" />,
            type: 'item',
            url: '/models',
            icon: AppsOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'articles',
            title: <FormattedMessage id="articles" />,
            type: 'item',
            url: '/articles',
            icon: ClassOutlinedIcon,
            breadcrumbs: false
        }
        // config.hideForMVP
        // {
        //     id: 'workFLow',
        //     title: <FormattedMessage id="work-flow" />,
        //     type: 'item',
        //     url: 'users/me/workflows',
        //     icon: icons.IconShape2,
        //     breadcrumbs: false
        // }
    ]
};

export default solutionDirectory;
