import React, { useState, useEffect } from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// material-ui
import { IconPoint } from '@tabler/icons';

// project imports
import NavGroup from '../MenuList/NavGroup';

// services
import userService from 'services/user';

// constant
const icons = { IconPoint };

// ==============================|| SIDEBAR MENU LIST ||============================== //

const CollectionList = () => {
    const [menuItem, setmenuItem] = useState({
        id: '',
        title: '',
        type: '',
        children: []
    });

    const getCollection = async () => {
        const myCollection = await userService.getCollection('me');
        const menuItem = {
            id: 'collection',
            title: <FormattedMessage id="collection" />,
            type: 'group',
            children: []
        };
        await myCollection.data.map((collection) =>
            menuItem.children.push({
                id: collection.name,
                title: collection.name,
                type: 'item',
                url: `/collections/${collection.key}`,
                icon: icons.IconPoint,
                breadcrumbs: false
            })
        );
        await setmenuItem(menuItem);
    };

    useEffect(() => {
        getCollection();
    }, []);

    return menuItem.children ? <NavGroup key={menuItem.id} item={menuItem} /> : '';
};

export default CollectionList;
