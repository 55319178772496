import axios from 'utils/axios';

const baseUrl = '/api/users';

/**
 * @param {object} credentials {username: string, password: string}
 * @returns
 */
const login = async (credentials) => {
    const response = await axios.post(`${baseUrl}/login`, credentials);
    return response;
};

/**
 * @param {object} userKey {userKey: string}
 * @returns
 */
const logout = async (userKey) => {
    const response = await axios.post(`${baseUrl}/logout`, userKey);
    return response;
};

/**
 * @param {object} newUser
 * @returns
 */
const create = async (newUser) => {
    const response = await axios.post(`${baseUrl}/`, newUser);
    return response;
};

/**
 * @param {object} userKey {userKey: string}
 * @returns
 */
const remove = async (userKey) => {
    const response = await axios.delete(`${baseUrl}/${userKey}`);
    return response;
};

/**
 * @param {object} userInfo
 * @returns
 */
const update = async (userInfo) => {
    const response = await axios.patch(`${baseUrl}/`, userInfo);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getOne = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}`);
    return response;
};

/**
 * @returns
 */
const getList = async () => {
    const response = await axios.get(`${baseUrl}/`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getFriend = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/friends`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getArticle = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/articles`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getCollection = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/collections`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getModel = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/models`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getWorkflow = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/workflows`);
    return response;
};

/**
 * @param {string} userKey
 * @returns
 */
const getDoc = async (userKey) => {
    const response = await axios.get(`${baseUrl}/${userKey}/documents`);
    return response;
};

export default { login, logout, create, remove, getOne, getList, getFriend, update, getArticle, getCollection, getModel, getDoc };
