import { FormattedMessage } from 'react-intl';

// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = { IconSettings };

// ===========================|| WIDGET MENU ITEMS ||=========================== //

const setting = {
    id: 'setting',
    title: <FormattedMessage id="setting" />,
    type: 'group',
    children: [
        {
            id: 'setting',
            title: <FormattedMessage id="setting" />,
            type: 'item',
            url: '/users/me/setting',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default setting;
