import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// load services
import userService from 'services/user';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import config from 'config';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (localUser) => {
    if (localUser) {
        localStorage.setItem(`${config.localStorage}`, JSON.stringify(localUser));
        axios.defaults.headers.common.Authorization = `${localUser.token}`;
    } else {
        localStorage.removeItem(`${config.localStorage}`);
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    useEffect(() => {
        const init = async () => {
            try {
                const localUser = JSON.parse(window.localStorage.getItem(`${config.localStorage}`));
                if (localUser && verifyToken(localUser.token)) {
                    setSession(localUser);
                    const response = await userService.getOne(localUser.key);
                    const user = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (username, password) => {
        const response = await userService.login({ username, password });
        const token = response.data.token;
        const user = response.data;
        setSession(user);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        // const id = chance.bb_pin();
        // const response = await axios.post('/api/account/register', {
        //     id,
        //     email,
        //     password,
        //     firstName,
        //     lastName
        // });
        // let users = response.data;
        const response = await userService.create({
            email,
            password,
            firstName,
            lastName
        });

        let users = response.data;

        if (
            window.localStorage.getItem(`${config.localStorage}`) !== undefined &&
            window.localStorage.getItem(`${config.localStorage}`) !== null
        ) {
            const localUsers = window.localStorage.getItem(`${config.localStorage}`);
            users = [
                ...JSON.parse(localUsers),
                {
                    email,
                    password,
                    fullName: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem(`${config.localStorage}`, JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
