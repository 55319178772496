import { FormattedMessage } from 'react-intl';

// assets
import { IconDeviceDesktopAnalytics, IconLayoutKanban } from '@tabler/icons';
import CableIcon from '@mui/icons-material/Cable';

// constant
const icons = { IconDeviceDesktopAnalytics, IconLayoutKanban };

// ===========================|| WIDGET MENU ITEMS ||=========================== //

const workspace = {
    id: 'workspace',
    title: <FormattedMessage id="workspace" />,
    type: 'group',
    children: [
        // {
        //     id: 'workspace',
        //     title: <FormattedMessage id="workspace" />,
        //     type: 'item',
        //     url: 'comingsoon', // '/users/me/workspace',
        //     icon: icons.IconDeviceDesktopAnalytics,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'kanban',
        //     title: <FormattedMessage id="kanban" />,
        //     type: 'item',
        //     url: 'comingsoon', // '/users/me/kanban',
        //     icon: icons.IconLayoutKanban,
        //     breadcrumbs: false
        // }
        {
            id: 'Workflow',
            title: <FormattedMessage id="Workflow" />,
            type: 'item',
            url: 'comingsoon', // '/users/me/workspace',
            icon: CableIcon,
            breadcrumbs: false
        }
    ]
};

export default workspace;
