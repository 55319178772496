import React from 'react';
import setting from './setting';
import solutionDirectory from './solutionDirectory';
import workspace from './workspace';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [solutionDirectory, workspace]
};

export default menuItems;
