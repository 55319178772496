const Typography = (theme, borderRadius, fontFamily) => ({
    fontFamily,
    h6: {
        fontWeight: 500,
        color: 'black',
        fontSize: '0.75rem'
    },
    h5: {
        fontSize: '0.875rem',
        color: 'black',
        fontWeight: 500
    },
    h4: {
        fontSize: '1rem',
        color: 'black',
        fontWeight: 600
    },
    h3: {
        fontSize: '1.25rem',
        color: 'black',
        fontWeight: 600
    },
    h2: {
        fontSize: '1.5rem',
        color: 'black',
        fontWeight: 700
    },
    h1: {
        fontSize: '2.125rem',
        color: 'black',
        fontWeight: 700
    },
    cardtitle: {
        fontSize: '1.1rem',
        fontWeight: 500,
        color: 'black'
    },
    cardauthor: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.darkTextSecondary
    },
    cardcontent: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.darkTextSecondary
    },
    cardcontentblack: {
        fontSize: '0.75rem',
        fontWeight: 400
    },
    simplecardcontentblack: {
        fontSize: '0.8rem',
        fontWeight: 400
    },
    modelResultsCardContent: {
        fontSize: '18px',
        color: 'black',
        lineHeight: 2,
        fontFamily: 'Inter'
    },
    articleTitle: {
        fontSize: '2.5rem',
        color: 'black',
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'Georgia'
    },
    articleH1: {
        fontSize: '2.25rem',
        color: 'black',
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'Inter'
    },
    articleH2: {
        fontSize: '2rem',
        color: 'black',
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'Inter'
    },
    articleH3: {
        fontSize: '1.75rem',
        color: 'black',
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'Inter'
    },
    articleH4: {
        fontSize: '1.5rem',
        color: 'black',
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'Inter'
    },
    articleBody: {
        fontSize: '1.25rem',
        paddingTop: '15px',
        paddingBottom: '5px',
        color: 'black',
        fontWeight: 400,
        lineHeight: '1.5em',
        fontFamily: 'Georgia'
    },
    subtitle1: {
        fontSize: '1.2rem', // '0.875rem',
        fontWeight: 500,
        color: theme?.customization?.navType === 'dark' ? theme.heading : theme.textDark
    },
    subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        color: theme.darkTextSecondary
    },
    caption: {
        fontSize: '0.75rem',
        color: theme.darkTextSecondary,
        fontWeight: 400
    },
    body1: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.334em'
    },
    body2: {
        fontSize: '1rem',
        // letterSpacing: '1rem',
        textAlign: 'justify',
        fontWeight: 400,
        lineHeight: '1.5em'
        // color: theme.darkTextPrimary
    },
    button: {
        textTransform: 'capitalize'
    },
    customInput: {
        marginTop: 1,
        marginBottom: 1,
        '& > label': {
            top: 23,
            left: 0,
            color: theme.palette.grey[500],
            '&[data-shrink="false"]': {
                top: 5
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    mainContent: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
        width: '100%',
        minHeight: 'calc(100vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginTop: '88px',
        marginRight: '20px',
        borderRadius: `${borderRadius}px`
    },
    menuCaption: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: theme.palette.grey[600],
        padding: '6px',
        textTransform: 'capitalize',
        marginTop: '10px'
    },
    subMenuCaption: {
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        textTransform: 'capitalize'
    },
    commonAvatar: {
        cursor: 'pointer',
        borderRadius: '8px'
    },
    smallAvatar: {
        width: '22px',
        height: '22px',
        fontSize: '1rem'
    },
    mediumAvatar: {
        width: '34px',
        height: '34px',
        fontSize: '1.2rem'
    },
    largeAvatar: {
        width: '44px',
        height: '44px',
        fontSize: '1.5rem'
    }
});

export default Typography;
