/* eslint-disable */ 
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const SocialProfile = Loadable(lazy(() => import('views/users/social-profile')));
const AccountProfile = Loadable(lazy(() => import('views/users/account-profile')));
const ArticleList = Loadable(lazy(() => import('views/library/ArticleList')));
const ArticleDetail = Loadable(lazy(() => import('views/library/ArticleDetail')));
const ArticleEditor = Loadable(lazy(() => import('views/library/ArticleEditor')));
const ModelList = Loadable(lazy(() => import('views/model/ModelList')));
const ModelDetail = Loadable(lazy(() => import('views/model/ModelDetail')));
const ModelsEditor = Loadable(lazy(() => import('views/model/ModelsEditor')));
// const WorkFlows = Loadable(lazy(() => import('views/workFlow/WorkFlows')));
const Kanban = Loadable(lazy(() => import('views/kanban')));
// const Workspace = Loadable(lazy(() => import('views/workspace')));
const CollectionDetail = Loadable(lazy(() => import('views/collection/CollectionDetail')));
const CategoryDetail = Loadable(lazy(() => import('views/category/CategoryDetail')));
// const ProjectDetail = Loadable(lazy(() => import('views/workFlow/WorkFlowDetail/index')));
const ComingSoon = Loadable(lazy(() => import('views/ComingSoon')));
const Results = Loadable(lazy(() => import('views/search/results')));
const ContactUs = Loadable(lazy(() => import('views/contactUs')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/users/:userKey/:tab',
            element: <SocialProfile />
        },
        {
            path: '/users/:userKey/setting',
            element: <AccountProfile />
        },
        {
            path: '/users/:userKey/articles',
            element: <ArticleList />
        },
        {
            path: '/users/:userKey/models',
            element: <ModelList />
        },
        {
            path: '/users/:userKey/kanban',
            element: <Kanban />
        },
        // {
        //     path: '/users/:userKey/workspace',
        //     element: <Workspace />
        // },
        // {
        //     path: '/users/:userKey/workflows',
        //     element: <WorkFlows />
        // },
        {
            path: '/categories/:categoryKey/',
            element: <CategoryDetail />
        },
        {
            path: '/collections/:collectionKey/',
            element: <CollectionDetail />
        },
        {
            path: '/articles/',
            element: <ArticleList />
        },
        {
            path: '/articles/create/',
            element: <ArticleEditor />
        },
        {
            path: '/articles/:articleKey/',
            element: <ArticleDetail />
        },
        {
            path: '/articles/:articleKey/edit',
            element: <ArticleEditor />
        },
        {
            path: '/models/',
            element: <ModelList />
        },
        {
            path: '/models/create/',
            element: <ModelsEditor />
        },
        {
            path: '/models/:modelKey/',
            element: <ModelDetail />
        },
        {
            path: '/models/:modelKey/edit',
            element: <ModelsEditor />
        },
        // {
        //     path: '/workflows/:workflowKey/',
        //     element: <ProjectDetail />
        // },
        {
            path: '/comingsoon/',
            element: <ComingSoon />
        },
        {
            path: '/results/',
            element: <Results />
        },
        {
            path: '/contactus/',
            element: <ContactUs />
        }
    ]
};

export default MainRoutes;
